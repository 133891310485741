import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import CtaButton from '../components/CtaButton'


const NotFoundPage = () => (
  <Layout>
  <SEO title="404: Not found" />

  <div className="container">

    <section className="notFound">
        <h1>Oops!</h1>
        <p>The page you are looking for could not be found</p>
    </section>

    <section className="notFound-redirect">

      <p>Learn how InHero can help you get found, get chosen and become the preferred choice.</p>
      <CtaButton/>

    </section>


  </div>
    
  </Layout>
)

export default NotFoundPage
